'use strict'

/* ----- Animation ----- */
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

/* --- Default scroll animations --- */
const defaultEase = "Power3.easeInOut";
const defaultDuration = 1;
const staggerVal = 0.12;

function setScrollAnimation(sel, x, y, animateOpacity, duration, ease, direction, start) {
  const items = document.querySelectorAll(sel);
  if (!items) return;

  if (direction === 'to-right') {
    x = -x;
  }

  items.forEach(item => {
    gsap.timeline({
      scrollTrigger: {
        start: start ? start : 'top 92%',
        toggleActions: "play none none none",
        trigger: item,
      }
    })
      .from(item, {
        y: y ? y : 0,
        x: x ? x : 0,
        autoAlpha: animateOpacity ? 0 : 1,
        ease: ease ? ease : defaultEase,
        duration: duration ? duration : defaultDuration,
      });
  })
}

function scrollStaggerAnimation(containerSel, sel, x, y, staggerVal, animateOpacity, duration, ease) {
  // container is also a trigger for nested elements
  const container = document.querySelectorAll(containerSel)
  if (!container) return;

  container.forEach(container => {
    const items = container.querySelectorAll(`${containerSel} ${sel}`);
    if (!items) return;

    gsap.timeline({
      scrollTrigger: {
        start: 'top 95%',
        toggleActions: "play none none none",
        trigger: container,
      }
    })
      .from(items, {
        y: y ? y : 0,
        x: x ? x : 0,
        autoAlpha: animateOpacity ? 0 : 1,
        stagger: staggerVal,
        ease: ease ? ease : defaultEase,
        duration: duration ? duration : defaultDuration,
      });
  })
}
/* ----- /Default scroll animations ----- */

/* ----- Scripts ----- */
// Run
document.addEventListener('DOMContentLoaded', () => {
  headerScrollInit()

  // prices
  scrollStaggerAnimation('.prices-section__prices-table', '.prices-table__row', -200, 0,
    staggerVal, true, .75, defaultEase)

  // fade-in
  setScrollAnimation('.animate-opacity', 0,0, true);
  // fade-in left/right
  setScrollAnimation('.animate-to-right', -100, 0, true);
  setScrollAnimation('.animate-to-left', 100, 0, true);
  // fade-in top/bottom
  setScrollAnimation('.animate-to-bottom', 0, 100, true);
  setScrollAnimation('.animate-to-top', 0, -100, true);

  /// on scroll
  // fade-in
  setScrollAnimation('.animate-scroll-opacity', 0,0, true);
  // fade-in left/right
  setScrollAnimation('.animate-scroll-to-right', -100, 0, true);
  setScrollAnimation('.animate-scroll-to-left', 100, 0, true);
  setScrollAnimation('.animate-scroll-to-bottom', 0, 100, true);
})

function headerScrollInit() {
  const links = Array.from(document.querySelectorAll('.header-nav__item'))
  links.push(document.querySelector('.header__logo'))

  links.forEach(link => {
    const index = link.href.indexOf('#')
    const selector = link.href.slice(index)

    if (link.href) {
      link.addEventListener('click', (event) => {
        event.preventDefault()
        gsap.to(window, {duration: .65, scrollTo: {y: `${selector}`, offsetY: 100}});
      })
    }
  })
}
/* ----- /Scripts ----- */
