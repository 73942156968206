/* ----- Main ----- */
const headerMenuBtn = document.querySelector('.header__menu-button')

document.addEventListener('DOMContentLoaded', () => {
  // Mobile menu
  headerMenuBtn.addEventListener('click', toggleMenu)
  const navItems = document.querySelectorAll('.header-nav__item')
  navItems.forEach(item => {
    item.addEventListener('click', closeMenu)
  })

  // Swiper
  const swiperThumbs = new Swiper('.swiper-thumbs', {
    loop: true,
    spaceBetween: 10,
    slidesPerView: 4,
    freeMode: true,
    watchSlidesProgress: true,
  });

  const swiper = new Swiper('.swiper', {
    loop: true,
    spaceBetween: 50,
    slidesPerView: 'auto',
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    thumbs: {
      swiper: swiperThumbs,
    },
    breakpoints: {
      640: {
        slidesPerView: 1,
      },
    }
  });
})

function toggleMenu() {
  headerMenuBtn.classList.toggle('menu-button--active')
  document.body.classList.toggle('mobile-menu-active')
}
function closeMenu() {
  const delay = .45

  gsap.to('.header-nav', {
    delay: delay,
    autoAlpha: 0,
    opacity: 0,
    duration: 0,
    onComplete: () => {
      headerMenuBtn.classList.remove('menu-button--active')
      document.body.classList.remove('mobile-menu-active')
    }
  })
}
/* ----- /Main ----- */
